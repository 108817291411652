import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const DeclinedReasonField = ({ source, record = {} }) => {
  const color = (category) => {
    if (category === 'APPROVE') {
      return 'success.main';
    } else if (category === 'WARNING') {
      return 'warning.main';
    }
    return 'error.main';
  };
  let errors = null;
  if (record[source]) {
    errors = [];

    let records = [];

    try {
      records = JSON.parse(record[source]);

      let i = 0;
      for (let record of records) {
        errors.push(
          <Box key={i} component="li" color={color(record.category)}>
            {record.message}
          </Box>
        );
        i++;
      }
    }
    catch (err) {
      records = record[source];

      records = records.replace(/[\[\]]/g, '').split(',').map(item => item.trim());

      let i = 0;
      for (let record of records) {
        errors.push(
          <Box key={i} component="li" color={color(record)}>
            {record}
          </Box>
        );
        i++;
      }

    }
  }

  return (
    <Typography component="ul" variant="body1">
      {errors}
    </Typography>
  );
};

DeclinedReasonField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default DeclinedReasonField;

import { List, Datagrid, TextField, TextInput, NumberField, BooleanField, DateInput } from 'react-admin';
import CustomDateField from '../../shared/components/CustomDateField';
import { ListActions } from '../../shared/components/ListActions';
import { useDataGridStyles } from '../../shared/styles/DataGridStyles';

export const ReferralsList = (props) => {
  const classes = useDataGridStyles();

  const rowStyle = (record, index) => ({
      backgroundColor: record?.awarded ? '#efe' : '',
  });

  return (
    <List
      {...props}
      filters={filters}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      bulkActionButtons={false}
      actions={<ListActions filters={filters} />}
    >
      <Datagrid rowStyle={rowStyle} classes={classes}>
        <TextField source="id" />
        <TextField 
          source="user.email"
          label="Referrer" />
        <TextField 
          source="referredUser.email"
          label="Referred user"
        />
        <NumberField
          source="rewardAmount"
          label="Amount"
          options={{ maximumFractionDigits: 2 }}
        />
        <BooleanField 
          source="awarded" 
          label="Awarded"
        />
        <CustomDateField source="createdAt" format="LLLL" />
      </Datagrid>
    </List>
  );
};

const filters = [
  <TextInput label="Search by e-mail" source="email" alwaysOn />,
  <DateInput
    label="Start Date"
    source="from"
  />,
  <DateInput
    label="End Date"
    source="to"
  />
];